

















































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import TopWord from '@/components/HomeWordTop.vue'
import countTo from 'vue-count-to'
import {
  getproductIntroduction,
  getSolution,
  getinfo,
  gethomePageImgList
} from '@/api/home'
import { getHomePageArticle } from '@/api/news'
@Component({
  components: {
    TopWord,
    countTo
  }
})
export default class Home extends Vue {
  top1type = false
  top2type = false
  top3type = false
  top4type = false
  top5type = false
  top6type = false
  top7pictype = false
  top4numtype = false
  top5numtype = false
  top5numtype2 = false

  fourObj = {}
  fifthObj = {}
  sevenObj = {}
  seventhType = 0
  productIntroduction = {}
  Solution = {}
  test = 0
  PageImgList = []
  articleTitleVoList = []
  productArticleTitleVoList = []
  articleClassifyVoList = []
  created() {
    gethomePageImgList().then((res) => {
      this.PageImgList = res.data
    })
    getproductIntroduction().then((res) => {
      res.data.productVoList.forEach((item: any) => {
        item.packageVoList = item.packageVoList.slice(0, 3)
        item.packageVoList.forEach((item1: any, index: any) => {
          if (index == 0) {
            item1.type = true
          } else {
            item1.type = false
          }
        })
      })
      this.productIntroduction = res.data
    })
    getSolution().then((res) => {
      res.data.productVoList.forEach((item: any) => {
        if (item.succeedVoImgList) {
          item.succeedVoImgList = item.succeedVoImgList.slice(0, 4)
          item.succeedVoImgList.forEach((item1: any, index: any) => {
            item1.type = false
          })
        } else {
          item.succeedVoImgList = []
        }
      })
      this.Solution = res.data
    })
    getinfo({ moduleType: 3 }).then((res) => {
      res.data.pageSettingsDetailInfoList.forEach((item: any) => {
        item.pictype = false
      })
      this.fourObj = res.data
    })
    getinfo({ moduleType: 4 }).then((res) => {
      this.fifthObj = res.data
    })
    getinfo({ moduleType: 5 }).then((res) => {
      this.sevenObj = res.data
    })
    getHomePageArticle().then((res) => {
      res.data.articleTitleVoList.forEach((item: any) => {
        item.wordtype = false
      })
      res.data.articleClassifyVoList.forEach((item: any) => {
        item.wordtype = false
      })
      res.data.productArticleTitleVoList.forEach((item: any) => {
        item.wordtype = false
      })
      this.articleTitleVoList = res.data.articleTitleVoList
      this.productArticleTitleVoList = res.data.productArticleTitleVoList
      this.articleClassifyVoList = res.data.articleClassifyVoList
    })
  }
  mounted() {
    //  console.log("asdsadsadasdasd",this.top1);
    //  console.log("asdsadsadasdasd",document.getElementById('top1').getBoundingClientRect());
    window.addEventListener('scroll', this.scrollToTop1)
    window.addEventListener('scroll', this.scrollToTop2)
    window.addEventListener('scroll', this.scrollToTop3)
    window.addEventListener('scroll', this.scrollToTop4)
    window.addEventListener('scroll', this.scrollToTop5)
    window.addEventListener('scroll', this.scrollToTop6)
    window.addEventListener('scroll', this.scrollToTop3pic)
    window.addEventListener('scroll', this.scrollToTop4num)
    window.addEventListener('scroll', this.scrollToTop5num)
    window.addEventListener('scroll', this.scrollToTop5num2)
    window.addEventListener('scroll', this.scrollToTop7)
    window.addEventListener('scroll', this.scrollToTop7word1)
    window.addEventListener('scroll', this.scrollToTop7word2)
    window.addEventListener('scroll', this.scrollToTop7word3)
    this.$nextTick(() => {
      ; (this as any).$refs.navTabs.$refs.nav.$nextTick(() => {
        console.log((this as any).$refs.navTabs.$refs.nav.$refs, 'test')
        console.log('执行')
        const tarfet1 = document.getElementsByClassName('el-tabs__item is-left')
        // 此时tab的nav才渲染dom 否则拿不到el-tabs__item
        window.addEventListener('load', () => {
          let target = document.getElementsByClassName('el-tabs__item is-left')
          // console.log(target, 'target')
          // console.log(target.length, 'target11')
          let that = this as any
          for (let i = 0; i < target.length; i++) {
            // console.log(target[i], i)

            target[i].addEventListener('mouseover', () => {
              // console.log(that.$refs.navTabs, 'aaaa111111111111111111111111')
              that.$el.__vue__.panes[0].$el._prevClass =
                'animate__animated animate__fadeInRight el-tab-pane'
              that.$refs.navTabs.handleTabClick(1, String(i))
            })
          }
        })
        window.addEventListener('scroll', () => {
          let target = document.getElementsByClassName('el-tabs__item is-left')
          // console.log(target, 'target')
          // console.log(target.length, 'target11')
          let that = this as any
          for (let i = 0; i < target.length; i++) {
            // console.log(target[i], i)

            target[i].addEventListener('mouseover', () => {
              that.$refs.navTabs.handleTabClick(1, String(i))
            })
          }
        })
      })
    })
  }
  // startAnimate() {
  //   const io = new IntersectionObserver(callback, option);
  //   io.observe(document.getElementById('top1') as HTMLElement);
  // }
  animate(obj: any, target: any, callback: any): void {
    // console.log(callback);  callback = function() {}  调用的时候 callback()

    // 先清除以前的定时器，只保留当前的一个定时器执行
    clearInterval(obj.timer)
    obj.timer = setInterval(function () {
      // 步长值写到定时器的里面
      // 把我们步长值改为整数 不要出现小数的问题
      // var step = Math.ceil((target - obj.offsetLeft) / 10);
      var step = (target - obj.offsetLeft) / 10
      step = step > 0 ? Math.ceil(step) : Math.floor(step)
      if (obj.offsetLeft == target) {
        // 停止动画 本质是停止定时器
        clearInterval(obj.timer)
        // 回调函数写到定时器结束里面
        // if (callback) {
        //     // 调用函数
        //     callback();
        // }
        callback && callback()
      }
      // 把每次加1 这个步长值改为一个慢慢变小的值  步长公式：(目标值 - 现在的位置) / 10
      obj.style.left = obj.offsetLeft + step + 'px'
    }, 15)
  }
  scrollToTop1() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top1`)
    scrollHeight = id.scrollHeight
    offsetTop = id.offsetTop
    top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
    bottom = scrollHeight + offsetTop
    // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
    if (scrollTop >= top && scrollTop <= bottom) {
      this.top1type = true
    }
    // });
  }
  scrollToTop2() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top2`)
    scrollHeight = id.scrollHeight
    offsetTop = id.offsetTop
    top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
    bottom = scrollHeight + offsetTop
    // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
    if (scrollTop >= top && scrollTop <= bottom) {
      this.top2type = true
    }
    // });
  }
  scrollToTop3() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top3`)
    scrollHeight = id.scrollHeight
    offsetTop = id.offsetTop
    top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
    bottom = scrollHeight + offsetTop
    // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
    if (scrollTop >= top && scrollTop <= bottom) {
      this.top3type = true
    }
    // });
  }
  scrollToTop4() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top4`)
    scrollHeight = id.scrollHeight
    offsetTop = id.offsetTop
    top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
    bottom = scrollHeight + offsetTop
    // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
    if (scrollTop >= top && scrollTop <= bottom) {
      this.top4type = true
    }
    // });
  }
  scrollToTop4num() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top4num`)
    if (id) {
      scrollHeight = id.scrollHeight
      offsetTop = id.offsetTop

      top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
      bottom = scrollHeight + offsetTop
      // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
      if (scrollTop >= top && scrollTop <= bottom) {
        this.top4numtype = true
      }
    }
    // });
  }
  scrollToTop5() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top5`)
    scrollHeight = id.scrollHeight
    offsetTop = id.offsetTop
    top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
    bottom = scrollHeight + offsetTop
    // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
    if (scrollTop >= top && scrollTop <= bottom) {
      this.top5type = true
    }
    // });
  }
  scrollToTop5num() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top5num`)
    scrollHeight = id.scrollHeight
    offsetTop = id.offsetTop
    top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
    bottom = scrollHeight + offsetTop
    // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
    if (scrollTop >= top && scrollTop <= bottom) {
      this.top5numtype = true
    }
    // });
  }
  scrollToTop5num2() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top5num2`)
    scrollHeight = id.scrollHeight
    offsetTop = id.offsetTop
    top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
    bottom = scrollHeight + offsetTop
    // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
    if (scrollTop >= top && scrollTop <= bottom) {
      this.top5numtype2 = true
    }
    // });
  }
  jumpToUrl(link = '') {
    if (link) {
      window.open(link, 'newWindow')
    }
  }
  scrollToTop6() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top6`)
    scrollHeight = id.scrollHeight
    offsetTop = id.offsetTop
    top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
    bottom = scrollHeight + offsetTop
    // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
    if (scrollTop >= top && scrollTop <= bottom) {
      this.top6type = true
    }
    // });
  }
  scrollToTop3pic() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    if (this.fourObj && (this.fourObj as any).pageSettingsDetailInfoList) {
      (this.fourObj as any).pageSettingsDetailInfoList.forEach((item: any) => {
        id = document.getElementById(`top3${item.id}`)
        scrollHeight = id.scrollHeight
        offsetTop = id.offsetTop
        top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
        bottom = scrollHeight + offsetTop
        // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
        if (scrollTop >= top && scrollTop <= bottom) {
          item.pictype = true
        }
      })
    }

  }
  scrollToTop7() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    // this.list.map( (i) => {
    id = document.getElementById(`top7`)
    scrollHeight = id.scrollHeight
    offsetTop = id.offsetTop
    top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
    bottom = scrollHeight + offsetTop
    // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
    if (scrollTop >= top && scrollTop <= bottom) {
      this.top7pictype = true
    }
    // });
  }
  scrollToTop7word1() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    this.articleTitleVoList.forEach((item: any) => {
      id = document.getElementById(`top7${item.id}`) || {}
      scrollHeight = id.scrollHeight
      offsetTop = id.offsetTop
      top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
      bottom = scrollHeight + offsetTop
      // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
      if (scrollTop >= top && scrollTop <= bottom) {
        item.wordtype = true
      }
    })
  }
  scrollToTop7word2() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    this.productArticleTitleVoList.forEach((item: any) => {
      id = document.getElementById(`top8${item.id}`) || {}
      scrollHeight = id.scrollHeight
      offsetTop = id.offsetTop
      top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
      bottom = scrollHeight + offsetTop
      // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
      if (scrollTop >= top && scrollTop <= bottom) {
        item.wordtype = true
      }
    })
  }
  scrollToTop7word3() {
    // 获取视窗高度
    const domHight = document.body.offsetHeight
    // dom滚动位置
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    // 获取监听元素
    let id: any
    // 获取监听元素本身高度
    let scrollHeight
    // 获取监听元素距离视窗顶部距离
    let offsetTop
    // 获取监听元素距离顶部高度-窗口高度
    let top
    // 元素距离底部的高度+元素本身高度
    let bottom
    this.articleClassifyVoList.forEach((item: any) => {
      id = document.getElementById(`top9${item.id}`) || {}
      scrollHeight = id.scrollHeight
      offsetTop = id.offsetTop
      top = offsetTop - domHight > 0 ? offsetTop - domHight : 0
      bottom = scrollHeight + offsetTop
      // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
      if (scrollTop >= top && scrollTop <= bottom) {
        item.wordtype = true
      }
    })
  }

  Jump(id: any) {

    const routeUrl = this.$router.resolve({
      path: '/newsdetails',
      query: { id }
    })
    window.open(routeUrl.href, '_blank')
  }

  inblock(num: any) {
    this.seventhType = num
  }
  outblock() {
    this.seventhType = 0
  }
  onChatClick() {
    ; (window as any).qimoChatClick()
  }
  gotologin() {
    window.location.href = `https://www.bgniao.cn/login?redirect=%2Fconsole`
  }
  imgGoto(type: any, link: any) {
    if (link) {
      if (type == 0) {
        window.location.href = `${link}`
      }
      if (type == 1) {
        window.open(link, '_blank')
      }
    }
  }
}
