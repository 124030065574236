















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class HomeWordTop extends Vue {
  @Prop()
  bgword?: string
  @Prop()
  smword?: string
}
